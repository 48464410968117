import React from "react";
import "./Briefservice.css";
import briefimage from '../assets/briefabout.png'
const BriefAbout = () => {
  return (
    <div className=" bg-slate-100">
      <div class="section-header text-center pt-5 ">
        <h2 class="fw-bold fs-1 text-4xl">
         ABOUT 
          <span class="b-class-secondary text-[#013f9c]"> OUR </span> COMPANY
        </h2>
        <p class="sec-icon">
          <i class="fa-solid fa-gear"></i>
        </p>
      </div>
      {/* <div className="flex flex-col md:flex-row items-center justify-center py-12 px-12">
        <div className="md:w-1/2 p-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            AI-Powered Solutions for Every Industry
          </h2>
          <p className="text-gray-700 mb-4">
            From healthcare and finance to manufacturing and retail, AI has the
            potential to drive innovation and drive business growth across
            diverse sectors. At [Your Company Name], we leverage advanced
            machine learning algorithms and deep learning techniques to develop
            custom AI solutions tailored to the unique needs and challenges of
            each industry.
          </p>
        </div>
        <div className="md:w-1/2 flex justify-center items-center">
          <img src="https://img.freepik.com/free-psd/metaverse-poses-backgrounds_23-2150145319.jpg?size=626&ext=jpg" />
        </div>
      </div> */}

      {/* Third Section */}
      <div className="flex flex-col md:flex-row-reverse items-center justify-center py-12 px-11 ">
        {/* Image on the left, Text on the right */}

        <div className="md:w-1/2 p-8">
          <h2 className="text-2xl text-left font-semibold mb-4 text-[#013f9c] ">
         Rementus Infotech Solutions Pvt. Ltd. 
          </h2>
          {/* <h2 className="text-xl text-left font-semibold mb-4 text-gray-800 ">
          Empowering Businesses Through Customized Software 
          </h2> */}
          {/* <h2 className="text-xl font-semibold mb-4 text-gray-800 pb-5">
          Building Your Digital Future: From Code to Conversion 
          </h2> */}
          <p className="text-gray-700 mb-4">
          Welcome to Rementus - Your One-Stop Solution for Customized Software and 
Digital Marketing Services! 
          </p>
          <p className="text-gray-700 mb-4">
          we're experts at developing custom software programs and putting innovative 
digital marketing techniques into practice to improve your internet visibility and spur commercial 
expansion. Whether you're a startup, small business, or a large enterprise, we've got you covered 
with our comprehensive suite of services designed to meet your unique needs.
          </p>
        </div>
        <div className="md:w-1/2 flex justify-center items-center">
          <img
            src={briefimage}
            alt="Mission Image"
            className="max-w-full h-auto rounded-lg shadow-lg"
          />
          {/* <img src="https://media.istockphoto.com/id/1332827275/photo/business-investment-and-ai-artificial-intelligence-data-analysis-technology-businessman-and.jpg?s=1024x1024&w=is&k=20&c=zeYHgTPnJHEiWOH9tAzXgViNVdd_ndAQx01Dgh8vLHQ=" alt="Mission Image" className="max-w-full h-auto rounded-lg shadow-lg" /> */}
        </div>
      </div>
    </div>
  );
};

export default BriefAbout;
