import About from "./Components/About";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer";
import ContactPage from "./Components/Contact";
import Navbar from "./Components/Navbar";
import Landing from "./LandingPage/Landing";

import Services from "./Components/Services";




function App() {
  return (
    <>
     <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Landing/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/contact" element={<ContactPage/>}/>
            <Route path="/service" element={<Services /> }/>
          </Routes>
          <Footer />
      </Router>
    
    </>
  );
}

export default App;
