
import React from "react";
import Slider from "react-slick";
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
const SimpleSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,  // Default number of slides to show
    slidesToScroll: 2,  // Default number of slides to scroll
    responsive: [
      {
        breakpoint: 1024,  // Adjust settings for tablets and smaller screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,  // Adjust settings for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

    return (
        <div className="w-full bg-black">
            <div className="w-[90%] mx-auto  pt-24">
            <div className="pb-10 group">
          <h1 className=" text-2xl font-bold text-center text-gray-100  font-anand">
            products in <span className="group-hover:text-[#00AFAC] text-2xl font-bold">ACTION.</span>
          </h1>
        </div>
                {/* <Slider {...settings} className=""> */}
                    <div className="px-2">
                        <div className="relative h-[450px]  group cursor-pointer overflow-hidden ">
                            <img
                                className=" grayscale h-full w-full group-hover:cursor-pointer group-hover:grayscale-0  object-cover duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                                src="https://i.ibb.co/h7MYtn1/6-product-in-action.png"
                                alt=""
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="relative  h-[450px] group cursor-pointer overflow-hidden ">
                            <img
                                className="w-full h-full grayscale group-hover:cursor-pointer group-hover:grayscale-0 object-cover duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                                src="https://i.ibb.co/nD4dqyj/5-product-in-action.png"
                                alt=""
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="relative h-[450px]  group cursor-pointer overflow-hidden ">
                            <img
                                className="h-full w-full  grayscale group-hover:cursor-pointer group-hover:grayscale-0  object-cover duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                                src="https://i.ibb.co/ggB7Q9P/4-product-in-action.png"
                                alt=""
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="relative h-[450px]  group cursor-pointer overflow-hidden ">
                            <img
                                className="h-full w-full  grayscale group-hover:cursor-pointer group-hover:grayscale-0  object-cover duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                                src="https://i.ibb.co/5887ShF/3-product-in-action.png"
                                alt=""
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="relative h-[450px]  group cursor-pointer overflow-hidden ">
                            <img
                                className="h-full w-full  grayscale group-hover:cursor-pointer group-hover:grayscale-0  object-cover duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                                src="https://i.ibb.co/thygnzm/2-product-in-action.png"
                                alt=""
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="relative h-[450px]  group cursor-pointer overflow-hidden ">
                            <img
                                className="h-full w-full  grayscale group-hover:cursor-pointer group-hover:grayscale-0  object-cover duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                                src="https://i.ibb.co/p25cD6N/1-product-in-action.png"
                                alt=""
                                loading="lazy"
                            />
                        </div>
                    </div>
                   
                {/* </Slider> */}
            </div>
        </div>
    );
};

export default SimpleSlider;

