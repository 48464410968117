
import React from "react";
import "./Briefservice.css";
import p1 from '../assets/reshot-icon-share-ZBSECWVM5Y.svg'
import web from '../assets/reshot-icon-development-WTRS9ZB8DM.svg'
import app from '../assets/reshot-icon-app-development-JZEXLB45P3.svg'
import content from '../assets/content.png'
import costomization from '../assets/customization.png'
import voice from '../assets/voice-mail.png';

const BriefService = () => {
  return (
    <section id="advertisers" class="advertisers-service-sec pt-5 pb-5">
      <div class="w-[90%] mx-auto">
        <div class=""> 
          <div class="section-header text-center">
            <h2 class="fw-bold fs-1 text-4xl">
              Our
              <span class="b-class-secondary"> It Services That </span> We offer
            </h2>
            <p class="sec-icon">
              <i class="fa-solid fa-gear"></i>
            </p>
          </div>
        </div>
        <div class="grid grid-col-4 w-full md:grid-cols-3 gap-4">
          {/* <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center"> */}
          <div class="">
            <div class="service-card">
              <div class="icon-wrapper overflow-hidden">
                <img
                 src={p1}
                  alt=""
                  className="w-full h-full"
                />
              </div>
              <h3>Website development</h3>
              <p>
              Your website is your digital storefront. Our expert team designs and 
develops stunning websites that captivate visitors and drive results. From sleek corporate sites to 
dynamic e-commerce platforms, we bring your vision to life.
              </p>
            </div>
          </div>
          <div class="">
            <div class="service-card">
              <div class="icon-wrapper overflow-hidden">
                <img
                 src={app}
                  alt=""
                  className="w-full h-full"
                />
              </div>

              <h3>App Development</h3>
              <p>
              Take your business to the fingertips of your customers with our mobile 
app development services. Whether it's iOS, Android, or cross-platform apps, we deliver intuitive 
and user-friendly experiences that drive engagement and conversions
              </p>
            </div>
          </div>
          <div class="">
            <div class="service-card">
              <div class="icon-wrapper overflow-hidden">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/2709552/icon-brand.svg"
                  alt=""
                  className="w-full h-full"
                />
              </div>
              <h3>Social Media Marketing</h3>
              <p>
              Leverage the power of social media to expand your reach and connect 
with your audience. Our social media marketing services support you in developing a powerful 
online identity, interacting with your fans, and establishing deep connections.
              </p>
            </div>
          </div>
          <div class="">
            <div class="service-card">
              <div class="icon-wrapper overflow-hidden">
                <img
                  src="https://www.aarohies.com/wp-content/uploads/2020/05/Software-Services_Aarohi.png"
                  alt=""
                  className="w-full h-full"
                />
              </div>
              <h3>Software Development</h3>
              <p>
              Our custom software development services can help you take 
use of technology's capabilities. From powerful enterprise solutions to web-based apps, we create 
custom software that precisely matches your business goals. 
              </p>
            </div>
          </div>
          <div class="">
            <div class="service-card">
              <div class="icon-wrapper">
              <div class="icon-wrapper overflow-hidden rounded-full">
                <img
                 src='https://cdn-icons-png.flaticon.com/512/6200/6200431.png'
                  alt=""
                  className="w-full h-full rounded-full"
                />
              </div>
             
              </div>
              <h3>Search Engine Optimization</h3>
              <p>
              Our SEO services will help you stand out online. We use tried
and-true techniques to raise your company's organic traffic, boost search engine rankings, and 
produce quality leads.
              </p>
            </div>
          </div>
          <div class="">
            <div class="service-card">
            <div class="icon-wrapper overflow-hidden rounded-full">
                <img
                  src={costomization}
                  // src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/2709552/icon-dev.svg"
                  alt=""
                  className="w-full h-full"
                />
              </div>
              {/* <div class="icon-wrapper">
                <i class="fa-solid fa-people-group"></i>
              </div> */}
              <h3>Email Marketing</h3>
              <p>
              Stay top-of-mind with your audience through targeted email campaigns. Our 
email marketing services help you deliver personalized content, nurture leads, and drive conversions 
with precision.
              </p>
            </div>
          </div>

          <div class="">
            <div class="service-card">
              <div class="icon-wrapper overflow-hidden">
                <img
                 src={content}
                  alt=""
                  className="w-full h-full"
                />
              </div>
              <h3>Content Marketing</h3>
              <p>
               Crafting engaging, informative content to attract, engage, and retain an audience. It drives brand awareness, fosters trust, and ultimately converts leads into customers through strategic storytelling and valuable information.
              </p>
            </div>
          </div>
          <div class="">
            <div class="service-card">
              <div class="icon-wrapper overflow-hidden">
                <img
                 src={voice}
                  alt=""
                  className="w-full h-full"
                />
              </div>
              <h3>Voice Mail Services</h3>
              <p>
              Efficient communication tools allowing callers to leave messages when the recipient is unavailable. They enhance accessibility, streamline business operations, and ensure timely responses to inquiries and important communications.
              </p>
            </div>
          </div>
          <div class="">
            <div class="service-card">
              <div class="icon-wrapper overflow-hidden">
                <img
                 src={p1}
                  alt=""
                  className="w-full h-full"
                />
              </div>
              <h3>Bulk SMS Services</h3>
              <p>
              Cost-effective platforms for sending mass text messages to a targeted audience. They facilitate quick communication, promotions, reminders, and alerts, maximizing outreach and engagement with minimal effort and expense.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BriefService;
