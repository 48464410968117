import React from 'react'
import './WhyChoose.css'
const WhyChoose = () => {
  return (
   <div className='w-full '>
    {/* <h1 className='text-center text-2xl text-gray-600 font-bold py-5'>Our Working Process </h1> */}
    <div class="section-header text-center pt-10">
        <h2 class="fw-bold fs-1 text-4xl">
          Our
          <span class="b-class-secondary text-[#013f9c]"> Working </span> Process
        </h2>
        <p class="sec-icon">
          <i class="fa-solid fa-gear"></i>
        </p>
      </div>
    <div className='w-[90%] mx-auto  p-2 grid md:grid-cols-2'>
        <section className='flex w-full justify-center flex-col items-center px-10 gap-10'>
            <div>
                <h1 className='text-gray-700 font-bold text-2xl pb-4'>The Power of Partnership</h1>
                <p className='text-gray-600'>
                Are you ready to partner with the best digital marketing agency in Noida and unlock the true potential of your business? Contact us today to discuss your digital marketing needs. Let's embark on this exciting journey together. Don't miss out on the opportunity to transform your online presence. Join hands with us, and let's set your brand on the path to digital greatness. Discover the possibilities with the best. Discover Your Digital Marketing Agency in Noida.
                </p>
            </div>
            <div>
                <h1 className='text-gray-700 font-bold text-2xl pb-4'>Why Choose Us</h1>
                <p className='text-gray-600'>
                As a top-notch digital marketing agency in Noida, we bring a perfect blend of creativity, innovation, and data-driven strategies to the table. Our dedicated team of experts is passionate about one thing: delivering results that exceed your expectations.
                </p>
            </div>
        </section>
    <section class="service-grid pb-5 pt-5">
            <div class="container">
                {/* <div class="row">
                    <div class="col-xl-12 text-center mb-4">
                        <div class="service-title">
                            <h4>Our Services</h4>
                        </div>
                    </div>
                </div> */}
                <div class="grid md:grid-cols-2 gap-2 ">
                    <div class="col-lg-4 col-md-6 text-center mb-3">
                        <div class="service-wrap">
                            <div class="service-icon">
                                <i class="fas fa-layer-group"></i>
                            </div>
                            <h4>Customized Solutions</h4>
                            <p> We understand that every business is unique. That's why we take a 
personalized approach to every project, ensuring that our solutions are tailored to your specific 
requirements.</p>
                            {/* <a href="#">Read More</a> */}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-3">
                        <div class="service-wrap">
                            <div class="service-icon">
                                <i class="far fa-chart-bar"></i>
                            </div>
                            <h4>Expertise & Experience:</h4>
                            <p> With years of experience in the industry, our team of skilled professionals 
brings expertise and innovation to every project we undertake.</p>
                            {/* <a href="#">Read More</a> */}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-3">
                        <div class="service-wrap">
                            <div class="service-icon">
                                <i class="fas fa-database"></i>
                            </div>
                            <h4>Client-Centric Approach</h4>
                            <p> Your success is our top priority. We work closely with you to understand 
your goals, address your challenges, and deliver solutions that exceed your expectations.</p>
                            {/* <a href="#">Read More</a> */}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-3">
                        <div class="service-wrap">
                            <div class="service-icon">
                                <i class="fas fa-cogs"></i>
                            </div>
                            <h4>Results-Driven Strategies</h4>
                            <p> We don't just deliver projects; we deliver results. Whether it's 
increased sales, improved brand visibility, or enhanced customer engagement, we measure our 
success by the impact we create for your business. </p>
                            {/* <a href="#">Read More</a> */}
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
   
   </div>
  )
}

export default WhyChoose