import React from 'react'
import './OurProject.css'
const OurProject = () => {
    return (
        <div>

            <div class="section-header text-center pt-5">
                <h2 class="fw-bold fs-1 text-4xl">
                    Our
                    <span class="b-class-secondary text-[#013f9c]"> Completed </span>Projects
                </h2>
                <p class="sec-icon">
                    <i class="fa-solid fa-gear"></i>
                </p>
            </div>



            <div class="box-wrapper">
                <figure class="shape-box shape-box_half">
                    <img src="https://images.unsplash.com/photo-1534670007418-fbb7f6cf32c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" alt="" />
                    <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
                    <figcaption>
                        <div class="show-cont">
                            <h3 class="card-no">01</h3>
                            <h4 class="card-main-title">Design</h4>
                        </div>
                        <p class="card-content"> Our design process focuses on creating visually appealing and user-friendly interfaces
                            that enhance the overall user experience. We pay attention to every detail, from color schemes and
                            typography to layout and navigation, to ensure that your website not only looks great but also
                            engages and captivates your audience. </p>
                        {/* <a href="#" class="read-more-btn">Read More</a> */}
                    </figcaption>
                    <span class="after"></span>
                </figure>
                <figure class="shape-box shape-box_half">
                    <img src="https://images.unsplash.com/photo-1512295767273-ac109ac3acfa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=375&q=80" />
                    <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
                    <figcaption>
                        <div class="show-cont">
                            <h3 class="card-no">02</h3>
                            <h4 class="card-main-title">DEVELOP</h4>
                        </div>
                        <p class="card-content"> Our development team is proficient in a wide range of technologies and platforms,
                            allowing us to create custom solutions that meet your unique requirements. Whether you need a
                            responsive website, a dynamic web application, or a mobile app, we have the expertise to bring your
                            vision to life.</p>
                        {/* <a href="#" class="read-more-btn">Read More</a> */}
                    </figcaption>
                    <span class="after"></span>
                </figure>
                <figure class="shape-box shape-box_half">
                    <img src="https://images.unsplash.com/photo-1498075702571-ecb018f3752d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=757&q=80" />
                    <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
                    <figcaption>
                        <div class="show-cont">
                            <h3 class="card-no">03</h3>
                            <h4 class="card-main-title">RESEARCH</h4>
                        </div>
                        <p class="card-content"> Before we begin any project, we conduct thorough research to gain insights into
                            your industry, target audience, and competitors. This research informs our design and development
                            process, enabling us to create strategies that are tailored to your specific needs and objectives.
                        </p>
                        {/* <a href="#" class="read-more-btn">Read More</a> */}
                    </figcaption>
                    <span class="after"></span>
                </figure>
                <figure class="shape-box shape-box_half">
                    <img src="https://images.unsplash.com/photo-1534669740902-e09e38a6a29f?ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80" />
                    <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
                    <figcaption>
                        <div class="show-cont">
                            <h3 class="card-no">04</h3>
                            <h4 class="card-main-title">PROMOTION</h4>
                        </div>
                        <p class="card-content"> Once your website is live, we don't stop there. We offer a range of digital
                            marketing services to promote your website and increase its visibility online. From SEO and social
                            media marketing to email campaigns and content marketing, we'll help you reach your target
                            audience and drive traffic to your site.</p>
                        {/* <a href="#" class="read-more-btn">Read More</a> */}
                    </figcaption>
                    <span class="after"></span>
                </figure>
            </div>
        </div>



    )
}

export default OurProject