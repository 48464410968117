// ContactPage.js

import React, { useState } from 'react';


const ContactPage = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Form submitted:', form);
    // You may want to integrate this with a backend service or an email API
  };

  return (
    <div className="min-h-screen bg-gray-100 pt-[75px]">
      {/* Header Section */}
      <header className="bg-blue-500 text-white py-16 text-center">
        <h1 className="text-4xl font-semibold">Contact Us</h1>
      </header>

      {/* Contact Form Section */}
      <section className=" w-full  bg-blue-800 grid grid-cols-1 md:grid-cols-2">
        {/* <div className='w-[90%] flex flex-col md:flex-row items-center justify-center'> */}

        
      <div className="bg-white pl-10 p-8 shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">Our Location</h2>
            <p className="text-gray-600">
            115,Shree Laxmi Plaza 
              <br />
              Andheri-west mumbai 400053
              <br />
              {/* Zip Code: 12345 */}
            </p>
            <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
            <p className="text-gray-600">
            info@rementus.in
              <br />
              +91 7977279846
            </p>
          </div>


        {/* <div className="bg-white   "> */}
        <div className="bg-white  p-8 ">
       
          <h2 className="text-2xl font-semibold mb-4">Send us a message</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-600">
                Your Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={form.name}
                onChange={handleChange}
                className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                Your Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-sm font-medium text-gray-600">
                Your Message
              </label>
              <textarea
                id="message"
                name="message"
                value={form.message}
                onChange={handleChange}
                rows="4"
                className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                required
              ></textarea>
            </div>
            <button 
            className="bg-blue-600 py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
            >
              Submit
            </button>
          </form>
       
       
        </div>
      
        {/* </div> */}
      </section>


    
    
    
    
    
    
    
    
    
    </div>
  );
};

export default ContactPage;
