import React from 'react';
import vission from '../assets/vission.png';
import mission from '../assets/mission.png';

const About = () => {
  return (
    <div className="bg-white  min-h-screen flex flex-col items-center justify-center pt-[70px]">
      {/* Top Section */}
      <div className="bg-blue-500 w-full text-white py-16 text-center border-2">
        <h1 className="text-4xl font-bold">About</h1>
        {/* <p></p> */}
      </div>

      {/* First Section */}
      <div className="flex flex-col md:flex-row items-center justify-center py-12 px-12 bg-gray-200">
        {/* Text on the left, Image on the right */}
        <div className="md:w-1/2 p-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Our Vission</h2>
          <p className="text-gray-700 mb-4">At Rementus, our vision is to be a global leader in digital innovation, empowering businesses to thrive
in the digital age. We envision a world where technology enables limitless possibilities, driving growth,
efficiency, and positive change for businesses and communities alike.</p>
        </div>
        <div className="md:w-1/2 flex justify-center items-center">
          <img src={vission} alt="Mission Image" className="max-w-full h-auto rounded-lg shadow-lg" />
        </div>
      </div>

      {/* Second  Section */}
      <div className="flex flex-col md:flex-row-reverse items-center justify-center py-12 px-11 bg-gray-300">
      
        <div className="md:w-1/2 p-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Our Mission</h2>
          <p className="text-gray-700 mb-4">Rementus aims to provide innovative software solutions and digital services that exceed client
expectations. They prioritize excellence, innovation, customer satisfaction, collaboration, and social
responsibility. They strive for high-quality solutions, explore new technologies, and build strong
relationships based on trust, transparency, and integrity. They value open communication, teamwork,
and shared goals, promoting ethical business practices and sustainability</p>
        </div>
        <div className="md:w-1/2 flex justify-center items-center">
  <img src={mission} alt="Mission Image" className="max-w-full h-auto rounded-lg shadow-lg" />
</div>
      </div>

    
      

    </div>
  );
};

export default About;














































