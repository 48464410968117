import React from 'react'
import SimpleSlider from './SimpleSlider'
import Photo from './Photo'
import BriefAbout from './BriefAbout'
import BriefService from './BriefService'
import HeroSection from './HeroSection'
import WhyChoose from '../Components/WhyChoose'
import OurProject from '../Components/OurProject'

const Landing = () => {
  return (
    <div className='overflow-hidden pt-[70px]'>
        <HeroSection/>
        <BriefAbout/>
        <BriefService/>
        <WhyChoose/>
        <OurProject/>
        {/* <Photo/> */}
    {/* <SimpleSlider/> */}
    </div>
  )
}

export default Landing