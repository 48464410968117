import React from 'react'
import sd from '../assets/SD.jpg'
import ss from '../assets/SD.webp'
import us from '../assets/US.jpg'
import as from '../assets/AS.webp'
import one  from '../assets/Serviceimg/1.png'
import two  from '../assets/Serviceimg/2.png'
import three  from '../assets/Serviceimg/3.png'
import four  from '../assets/Serviceimg/4.png'
import five  from '../assets/Serviceimg/5.png'
import six  from '../assets/Serviceimg/6.png'
import seven  from '../assets/Serviceimg/7.png'


function Services() {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center justify-center pt-[70px] ">
    <div className="bg-blue-500 w-full text-white py-16 text-center border-2">
    <h1 className="text-4xl font-bold">Services</h1>
  </div>
    {/* <div className='flex flex-wrap    items-center my-8 mx-3 pt-[70px] gap-3 md:flex-row md:items-center flex-shrink: 1 break-all mb-1  ' >
      <div className=' flex flex-grow flex-shrink p-4 bg-[#112d4e] text-white rounded-md border-t-4 border-indigo-500 break-all ' >Boost Conversion Rates</div>
      <div className=' flex flex-grow flex-shrink p-4 bg-[#112d4e] text-white rounded-md border-t-4 border-indigo-500 break-all ' >Improve Customer Engagement </div>
      <div className=' flex flex-grow flex-shrink  p-4 bg-[#112d4e] text-white rounded-md border-t-4 border-indigo-500' > Develop Trust & Loyalty</div>
      <div  className='flex flex-grow flex-shrink p-4 bg-[#112d4e] text-white rounded-md border-t-4 border-indigo-500' >Listen Consumers Voice</div>
      <div className='flex flex-grow flex-shrink p-4 bg-[#112d4e] text-white rounded-md border-t-4 border-indigo-500 text-center'>Increase Brand Exposure</div>
          
    </div> */}

    {/* Second Section */}
    <div className="flex flex-col md:flex-row items-center justify-center py-12 px-12 bg-gray-200">
      {/* Text on the left, Image on the right */}
      <div className="md:w-1/2 p-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Website Development
        </h2>
        <p className="text-gray-700 mb-4">Our website development services enhance your online presence, ensuring your website is a memorable and engaging first point of contact for potential customers. We use the latest technologies and best practices to create websites that meet your business goals and target  audience. From design to launch, we optimize your website for performance and search engines, and offer maintenance and support services.</p>
      </div>
      <div className="md:w-1/2 flex justify-center items-center">
        <img src={two} className="w-[500px] h-[300px] rounded-lg shadow-lg" />
      </div>
    </div>

    {/* Third Section */}
    <div className="flex flex-col md:flex-row-reverse items-center justify-center py-12 px-11 bg-white">
      {/* Image on the left, Text on the right */}
    
      <div className="md:w-1/2 p-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">App Development
        </h2>
        <p className="text-gray-700 mb-4">
        A mobile app is crucial for businesses, enhancing brand engagement and driving growth. Our 
app development services specialize in iOS and Android apps, ensuring a wide audience. Our 
experienced developers create customized apps, ensuring functionality, security, and 
scalability. From concept to launch, we offer post-launch support and maintenance services to 
keep your app competitive in the market.
        </p>
      </div>
      <div className="md:w-1/2 flex justify-center items-center">
<img src={three} alt="Application Software" className="w-[500px] h-[300px] rounded-lg shadow-lg" />
</div>
    </div>

    {/* Fourth Section */}
    <div className="flex flex-col md:flex-row items-center justify-center py-12 px-11 bg-gray-200">
      {/* Text on the left, Image on the right */}
      <div className="md:w-1/2 p-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Social Media Marketing
        </h2>
        <p className="text-gray-700 mb-4">
        Social media is a powerful tool that can help businesses grow their brand, engage their 
audience, and drive conversions. Our social media marketing services include content 
creation, community management, paid advertising, and analytics. Our team of experts 
develops customized strategies, ensuring effective campaigns and regular reports to help 
make informed decisions.

        </p>
      </div>
      <div className="md:w-1/2 flex justify-center items-center">
        <img src={four} alt="Mission Image" className="w-[500px] h-[300px] rounded-lg shadow-lg" />
      </div>
    </div>

    {/* Fifth Section */}
    <div className="flex flex-col md:flex-row-reverse items-center justify-center py-12 px-11 bg-white">
      {/* Image on the left, Text on the right */}
    
      <div className="md:w-1/2 p-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Software Development

        </h2>
        <p className="text-gray-700 mb-4">
        Our software development services help streamline business processes, enhance productivity, 
and drive growth. We collaborate with clients to understand their needs and goals, creating 
customized solutions that meet specific requirements. Our team uses the latest technologies 
and best practices, ensuring functionality, security, scalability, and user-friendliness. We 
offer maintenance and upgrade services to keep software up-to-date and aligned with 
evolving needs.
        </p>
      </div>
      <div className="md:w-1/2 flex justify-center items-center">
<img src={five} alt="Mission Image" className=" w-[500px] h-[300px] rounded-lg shadow-lg" />
</div>
    </div>

    <div className="flex flex-col md:flex-row items-center justify-center py-12 px-11 bg-gray-200">
      {/* Text on the left, Image on the right */}
      <div className="md:w-1/2 p-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Search Engine Optimization
        </h2>
        <p className="text-gray-700 mb-4">
        Improve your website's search engine rankings and traffic with our comprehensive SEO 
        services. Our team of experts audits your website, identifying areas for improvement. We 
        develop a customized SEO strategy, including keyword research, on-page and off-page 
        optimization, and content creation. Stay updated with SEO trends and best practices to stay 
        competitive. Our goal is to deliver measurable results for growth and maximize return on 
        investment.
        </p>
      </div>
      <div className="md:w-1/2 flex justify-center items-center">
        <img src={six} alt="Mission Image" className=" h-[300px] w-[500px]   rounded-lg shadow-lg" />
      </div>
    </div>

    {/* Third Section */}
    <div className="flex flex-col md:flex-row-reverse items-center justify-center py-12 px-11 bg-white">
      {/* Image on the left, Text on the right */}
    
      <div className="md:w-1/2 p-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Email Marketing
        </h2>
        <p className="text-gray-700 mb-4">
        Email marketing is a powerful tool for engaging audiences, driving conversions, and building 
        customer loyalty. Our services help create and execute successful campaigns, including 
        strategy, design, content creation, list management, and analytics. Our team of experts 
        understands your audience, develops targeted campaigns, and handles all aspects of your 
        marketing efforts, providing regular reports and insights to optimize campaigns and achieve 
        maximum results.
        </p>
      </div>
      <div className="md:w-1/2 flex justify-center items-center">
<img src={seven} alt="Application Software" className="w-[500px] h-[300px] rounded-lg shadow-lg" />
</div>
    </div>
  </div>
  )
}

export default Services