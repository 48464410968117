import React, { useState } from 'react'
// import Button from './Button';
import { Link } from 'react-router-dom'
import newLogo from "../assets/newLogo.png"

const Navbar = () => {
  let Links = [
    { name: "HOME", link: "/" },
    { name: "SERVICE", link: "/service" },
    { name: "ABOUT", link: "/about" },
    // { name: "BLOG'S", link: "/" },
    { name: "CONTACT", link: "/contact" },
  ];
  let [open, setOpen] = useState(false);
  return ( 
    <div className='shadow-md w-[100%] fixed top-0 left-0 z-50 '>
      <div className=' md:flex items-center justify-between bg-[hsl(0,0%,95%)] py-4 md:px-20 px-14'>
        <div 
        className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] 
          text-gray-100'>
          {/* <span className='text-3xl text-indigo-600 mr-1 pt-2'>
            <ion-icon name="logo-ionic"></ion-icon>
          </span> */}
          <div className='flex items-center'>   
          
          <img
            src={newLogo}
            alt="Logo"
            className="w-[20%] mr-4 cursor-pointer  rounded-sm"
          />
          </div>

        </div>

        <div onClick={() => setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
          <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
        </div>

        <ul className={` md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-[hsl(0,0%,95%)] md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-20 ' : 'top-[-690px]'}`}>
          {
            Links.map((link) => (
              <li key={link.name} className='md:ml-8 text-xl md:my-0 my-7' onClick={() => setOpen(!open)}>
                <Link to={link.link} className='text-black hover:text-gray-400 duration-500'>{link.name}</Link>
              </li>
            ))
          }
          {/* <button>
            Get Started
          </button> */}
        </ul>
      </div>
    </div>
  )
}

export default Navbar
